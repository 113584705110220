@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/sf-pro-text_regular.woff2');
}
@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/sf-pro-text_semibold.woff2');
}
@font-face {
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/sf-pro-text_bold.woff2');
}

@media screen and (min-width: 1310px) {
  .d-flex-wrap-md {
    display: flex;
    flex-wrap: nowrap;
  }
  .hide-lg {
    display: none;
  }
  i#burguer {
    display: none !important;
  }
  menu {
    display: flex !important;
    align-items: center;
  }

  menu > ul > li {
    display: inline-block;
  }

  header {
    display: flex;
    justify-content: center;
    padding: 10px;
    height: 78px;
  }

  /*header menu {
        width: 100%;
    }*/

  /* main {
        width: 768px;
    } */
}

@media screen and (max-width: 1309px) {
  .hide-md {
    display: none;
  }
  .rodape {
    display: grid !important;
  }
  .rodape div {
    justify-content: center;
  }
  .rodape nav a {
    margin-right: 10px;
    margin-left: 10px;
  }
  .rodape nav {
    justify-content: center;
    display: flex;
  }
  #itens, #itens ul {
    padding-left: 0 !important;
  }
  .mobile-menu {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .contato {
    justify-content: center !important;
    text-align: center;
    padding-top: 0 !important;
  }
  .redes nav {
    justify-content: center !important;
  }

  .d-flex-wrap-md {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 991px) {
  .carousel-control-next-icon,
  .carousel-control-prev-icon,
  .carousel-indicators {
    display: none !important;
    visibility: hidden;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

* {
  box-sizing: border-box;
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Text', -apple-system, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
  font-weight: 400;
  background-color: #f5f5f7;
}

textarea {
  resize: vertical;
}

.mobile-menu a img {
  filter: drop-shadow(0 0 1px rgba(110, 110, 110, 0.658))
    drop-shadow(0 0 1px rgba(110, 110, 110, 0.432));
  border-radius: 10px;
}

body {
  overflow-x: hidden;
  min-height: 100vh;
}

header {
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  backdrop-filter: saturate(180%) blur(10px);
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  margin: auto;
  width: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 100;
}

i#burguer {
  background-color: transparent;
  color: white;
  display: block;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

i#burguer:hover {
  background-color: white;
  color: black;
}

menu {
  display: none;
}

menu > ul {
  list-style-type: none;
}

menu > ul > li > a, #dropdownMenu2 {
  display: block;
  padding: 18px;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  color: white;
  width: 100%;
  /* background-color: #121a21; */
}

main {
  color: black;
  width: 100%;
  min-height: calc(100vh - 156px);
  padding-top: 78px;
}

main > div#prod {
  background-color: white;
  text-align: center;
  font-size: 40px;
  padding: 50px 20px 20px 20px;
  width: 100%;
}
.contato i {
  padding: 0 5px;
}
.xcoding {
  text-decoration: none;
  color: #fff;
}

main > div#princ {
  padding-top: 100px;
  margin: auto;
  text-align: center;
  height: 100%;
}

main > article#sobre {
  padding-top: 20px;
  text-align: center;
}

main > article#sobre h1 {
  font-size: 2em;
  padding: 20px 0px 40px;
}

main > article#sobre p {
  font-size: 1.5em;
  margin: auto;
  margin-bottom: 30px;
  text-align: justify;
  border-width: 1px;
  padding: 20px;
  border-radius: 6px;
}

.shadow {
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 7px;
}

main > article#around {
  text-align: center;
  max-width: 100%;
}

main > article#carrinho {
  padding: 20px;
  background-color: white;
  width: 100%;
  height: 30%;
  text-align: center;
}

main > article#carrinho p {
  padding: 20px;
  display: flex;
  margin-left: 27%;
  text-align: left;
  border-bottom: solid 1px #121a21;
  background-color: white;
  max-width: 43%;
  height: 30%;
  font-size: 1.5em;
}

footer {
  background-color: #1d1d1f;
  color: white;
  width: 100%;
  padding-bottom: 10px;
}

.ender {
  padding: 10px;
  text-align: center;
  background-position: right;
}

.rodape {
  padding: 20px 0px 10px;
  display: flex;
  justify-content: space-between;
}

.contato a {
  transition: all 0.2s;
}

.contato a:hover {
  color: #fff !important;
}

#sobre img:not(.not) {
  max-width: 50vw;
  object-fit: contain;
  object-position: center;
}

.img-sobre-vertical {
  object-fit: cover;
  height: 300px;
  width: 100%;
  object-position: center;
}

.contato {
  padding: 10px 0px;
  width: 100%;
  justify-content: space-between;
}

.redes > nav a {
  text-align: center;
  color: #fff;
  padding: 5px;
  margin-left: 20px;
}

.redes nav {
  display: flex;
  justify-content: end;
}

.text-grey {
  color: #6e6e73;
}
.text-grey2 {
  color: #d2d2db;
}

.redes > nav > a:hover {
  color: #d2d2db;
}

.nav1 {
  background-color: #121a21;
  text-align: center;
  color: white;
  padding: 5px;
}

.d-block {
  display: block !important;
}

.nav1#navp1 {
  background-color: aqua;
  text-align: center;
  color: black;
  padding: 5px;
}

.btn {
  text-align: center;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem;
}
.mt-3,
.my-3 {
  margin-top: 1rem;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.buy:hover {
  transition: all 0.3s;
  background-color: rgba(41, 53, 181, 0.9);
  color: #fff;
}

section#section-contact {
  background-color: #fff;
  text-align: center;
}

.btn:not(.btn-sm) {
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
  max-height: 44px;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
}

.btnlimpar {
  background-color: rgb(201, 28, 66) !important;
  color: #fff !important;
}
.btnlimpar:hover {
  background-color: rgba(201, 28, 66, 0.9) !important;
}

.btn-primary {
  background-color: rgb(41, 53, 181) !important;
  color: #fff;
}

.btn-primary:hover {
  background-color: rgba(41, 53, 181, 0.9) !important;
}

.form-control {
  padding: 0.571rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  outline: none;
  width: 100%;
  transition: 0.3s;
}

select.form-control {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 10px;
}

.form-control:focus {
  border-color: rgb(41, 53, 181) !important;
}

.form-control#textarea {
  height: 8vh;
}

.form-group {
  padding: 2px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (max-width: 991.9px) {
  .img-sobre {
    max-height: 200px;
    object-fit: cover;
    width: 100%;
    object-position: center;
  }
  .img-sobre-2 {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .d-md-flex {
    display: flex;
  }
  .img-sobre {
    object-fit: cover;
    width: 300px;
    object-position: right;
  }
  .img-sobre-2 {
    height: 300px;
    object-fit: contain;
  }
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.text-md {
  font-size: 1em !important;
}

.text-header {
  font-size: 2em;
  padding: 20px 0px 40px;
}

.produto span {
  margin-top: 10px;
  color: #1d1d1f;
  font-weight: 600;
}
.produto {
  display: block;
  text-decoration: none;
  display: inline-grid;
  margin-bottom: 10px;
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: drop-shadow(0px 0px 2px black) contrast(0.5) !important;
}

.ml-3 {
  margin-left: 1rem;
}

.avatar {
  border-radius: 100%;
}

.font-600 {
  font-weight: 600;
}

.text-start {
  text-align: start;
}

.decoration-none:not(:hover) {
  text-decoration: none;
}

.font-size-30 {
  font-size: 30px;
}

#princ .avatar {
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 7px;
  width: 60px;
  height: 60px;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type='email'] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type='password'] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.image-preview-group {
  width: 100%;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: center;

  div:not(.upload-options) {
    width: 100%;
    justify-content: center;
    display: flex;
    position: relative;
  }

  img {
    height: 250px;
    object-fit: scale-down;
  }
}

.upload-options {
  position: absolute;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  // background-color: var(--body-bg);
  border-radius: 6px;

  button {
    border: none !important;
    width: 100%;
    padding: 0.5857rem 0;
    margin-bottom: 0 !important;
    font-weight: 600;
    cursor: pointer;
    background-color: rgba(var(--bs-danger-rgb), 0.12);
    color: var(--bs-danger) !important;
    font-size: 1rem !important;
  }
  label {
    border: none !important;
    width: 100%;
    padding: 0.5857rem 0;
    margin-bottom: 0 !important;
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem !important;
    background-color: rgba(var(--bs-primary-rgb), 0.12);
    color: var(--bs-primary) !important;
  }

  & input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}

.image-preview {
  position: relative;
  overflow: hidden;
  &.preview {
    background-color: #ededed;
  }
}

textarea.form-control {
  transition: all 0.3s, height 0ms !important;
}
.object-fit {
  object-fit: scale-down;
}
.object-cover {
  object-fit: cover;
}

.card {
  border: none !important;
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%);
}
.btn-none {
  outline: none;
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.grow {
  transition: all 0.3s;
}
.grow:hover {
  transform: scale(1.03);
}

.bg-white {
  background-color: #fff;
}

span.text-product {
  margin-top: 10px;
  color: #1d1d1f;
  font-weight: 600;
  text-align: center;
}

.btn-round {
  border-radius: 98px !important;
}

.btn-primary-light {
  color: rgb(41, 53, 181);
  background-color: rgba(41, 53, 181, 0.12);

  &:hover {
    color: rgb(41, 53, 181) !important;
    background-color: rgba(41, 53, 181, 0.15) !important;
  }
}

.btn-dark-light {
  background-color: rgba(0, 0, 0, 0.06);

  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}

.position-relative {
  position: relative;
}

.pre-wrap {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-spacing: 2px;
  font-family: 'SF Pro Text', -apple-system, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

.product-image__img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.form-control.error {
  border-color: var(--bs-red) !important;
}
small.error {
  color: var(--bs-red) !important;
}

.d-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.mr-1 {
  margin-right: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.ml-3 {
  margin-left: 0.75rem;
}

.float-whats {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;
  background: #00e676;
  color: #fff !important;
  border-radius: 50px;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  animation: pulse 2s infinite;
  z-index: 999 !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(131, 181, 130, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(131, 181, 130, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(131, 181, 130, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(131, 181, 130, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(131, 181, 130, 0.4);
    box-shadow: 0 0 0 0 rgba(131, 181, 130, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 25px rgba(131, 181, 130, 0);
    -webkit-box-shadow: 0 0 0 25px rgba(131, 181, 130, 0);
    box-shadow: 0 0 0 25px rgba(131, 181, 130, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(131, 181, 130, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(131, 181, 130, 0);
    box-shadow: 0 0 0 0 rgba(131, 181, 130, 0);
  }
}

.swal2-html-container .swal2-title {
  color: #1d1d1f;
  font-family: 'SF Pro Text', -apple-system, Helvetica Neue, Helvetica, Arial,
    sans-serif !important;
}

.swal2-title {
  font-size: 1.575em !important;
}

.btn-servicos.produto {
  height: 100%;

  img {
    width: 80%;
    margin-left: 10%;
    height: 100%;
    object-fit: contain;
  }
}

.carousel.slide:has(.carousel-item.active.indicator-dark)
  .carousel-indicators
  [data-bs-target] {
  background-color: #000 !important;
}
.carousel.slide:has(.carousel-item.active.indicator-dark) {
  .carousel-control-prev,
  .carousel-control-next {
    filter: invert(1);
  }
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab li {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  list-style-type: none;

  & .link {
    // background-color: #b3b3b3;
    text-decoration: none;
    margin-left: -30px;
    color: rgb(82, 82, 82);
    padding: 10px;
    margin-right: 10px;
  }

  & .link.active {
    color: rgb(41, 53, 181) !important;;
  }
}

.hr-bottom {
    border-bottom: 1px solid rgba(0,0,0,0.12);
}
main .ql-editor {
  height: auto !important;
}

.border-left {
  border-left: rgb(218, 218, 218) solid 2px;
}